@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");

.hard-skills{
    background-image: url(../assets/hardskills.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;    
    height: 55vh;
}

.hard-skills-text{
    text-align: center;
    padding: 5vh;
}

.hard-skills-text > h1 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: white;
    padding: 2vh;
}
.hard-skills-text > h2 {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: white;
}

.hard-skills-bubbles{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 70%;
}

.hard-skill-bubble{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 15px;
}

@media screen and (max-width: 820px) {
    .hard-skills{
        height: auto;
    }
    
    .hard-skills-text > h1 {
        font-size: 18px;
    }
    .hard-skills-text > h2 {
        font-size: 14px;
    }
    .hard-skills-bubbles{
        width: 100%;
        padding-bottom: 5vh;
    }
}