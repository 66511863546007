@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");

.about-me {
  background-color: whitesmoke;
  display: flex;
}

.about-me-photo-desktop {
  width: 270px;
  height: 270px;
  padding: 10vh 5vh 10vh 20vh;
}
.about-me-photo-mobile {
  display: none;
}

.about-me-text {
  padding: 10vh 20vh 10vh 5vh;
  display: block;
}

.about-me-text > h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 24px;
  padding: 5px;
}
.about-me-text > h2 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 5px;
}
.about-me-text > p {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  padding: 5px;
  text-align: justify;
}

.btn {
  width: 200px;
  height: 50px;
  color: #fff;
  margin: 5px;
  background-color:rgb(59, 117, 234);
  border: none;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.btn:hover{
  background-color: rgba(59, 117, 234, 0.893);
  transition: 0.2s;
}

@media screen and (max-width: 820px) {
  .about-me {
    flex-direction: column;
    align-items: center;
  }
  .about-me-photo-desktop {
    display: none;
  }
  .about-me-photo-mobile {
    display: block;
    width: 270px;
    height: 270px;
    padding-bottom: 5vh;
  }
  .about-me-text {
    padding: 5vh;
    text-align: center;
  }
  .btn{
    margin-top: 5vh;
  }
}