.hero {
  height: 70vh;
  background-image: url(../assets/hero1.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
}

.hero--nav {
  display: flex;
  justify-content: space-around;
  padding: 30px 50px 30px 50px;
}

.hero--logo {
  width: 125px;
  height: 125px;
  border: 1px solid #fff;
}

.hero--nav--btns {
  display: flex;
  margin: 40px;
  text-align: center;
}

.nav-button {
  width: 110px;
  height: 20px;
  font-weight: 500;
  font-size: large;
  background-color: rgb(59, 117, 234);
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  padding: 10px;
}

.nav-button.active {
  background-color: #fff;
  color: rgb(59, 117, 234);
}

.hero--text {
  background-color: black;
  width: 40%;
  padding: 20px;
  text-align: center;
}

.hero--text > h1 {
  color: #fff;
  padding: 5px;
  font-size: 24px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}
.hero--text > h2 {
  color: rgb(59, 117, 234);
  padding: 5px;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.dropdown {
  display: none;
}

/* Mobile styles */
@media only screen and (max-width: 820px) {
  .hero {
    height: auto;
    background-image: url(../assets/hero1.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
  }

  .hero--nav {
    padding: 20px;
  }

  .hero--logo {
    width: 125px;
    height: 125px;
    border: 1px solid #fff;
  }

  .hero--nav--btns {
    display: none;
  }

  .hero--btn {
    display: none;
  }

  .hero--text {
    width: 90%;
    margin: 20px auto;
    padding: 10px;
  }

  .hero--text > h1 {
    font-size: 20px;
    padding: 5px;
  }

  .hero--text > h2 {
    font-size: 16px;
    padding: 5px;
  }

  .dropdown {
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .dropdown-content a {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
}
