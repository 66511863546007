.navbar {
    height: 33vh;
    background-image: url(../assets/hero1.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .nav-desktop{
    display: flex;
    justify-content: space-around;
    padding: 30px 50px 30px 50px;
  }
  
  .navbar--logo {
    width: 125px;
    height: 125px;
    border: 1px solid #fff;
  }
  
  .nav-content-desktop {
    display: flex;
    margin: 40px;
    text-align: center;
  }

  .nav-button {
    width: 110px;
    height: 20px;
    font-weight: 500;
    font-size: large;
    background-color: rgb(59, 117, 234);
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    padding: 10px;
  }

  .nav-button.active {
    background-color: #fff;
    color: rgb(59, 117, 234);
  }

  .dropdown-nav-mobile {
    display: none;
  }
  
  /* Mobile styles */
  @media only screen and (max-width: 820px) {
  
    .navbar {
      height: auto;
      background-image: url(../assets/hero1.jpeg);
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
    }
  
    .nav-desktop {
      padding: 20px;
    }
  
    .navbar--logo {
      width: 125px;
      height: 125px;
      border: 1px solid #fff;
    }
  
    .nav-content-desktop {
      display: none;
    }
    
    .dropdown-nav-mobile {    
      display: inline-block;
    }
  
    .dropdown-content-mobile {   
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
    .dropdown-content-mobile a {
      font-family: "Inter", sans-serif;
      font-weight: 500;
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
    .dropdown-content-mobile a:hover {
      background-color: #f1f1f1;
    }
    .dropdown-nav-mobile:hover .dropdown-content-mobile {
      display: block;      
    }
  }
  