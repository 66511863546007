.read-more-text {
  background-color: whitesmoke;
  padding: 5vh;
}

.read-more-text > h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  padding-bottom: 5vh;
}

.read-more-text > h2 {
  color: rgb(59, 117, 234);
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 5px;
  text-align: center;
}

.read-more-text > p {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  padding: 5px;
  text-align: justify;
  padding: 1vh 5vh 1vh 5vh;
}

.hard-skills {
  background-image: url(../assets/hardskills.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 55vh;
}

.hard-skills-text {
  text-align: center;
  padding: 5vh;
}

.hard-skills-text > h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: white;
  padding: 2vh;
}
.hard-skills-text > h2 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: white;
}

.hard-skills-bubbles {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 70%;
}

.hard-skill-bubble {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 15px;
}

@media screen and (max-width: 820px) {
  .read-more-text {
    flex-direction: column;
    align-items: center;
  }
  .hard-skills {
    height: auto;
  }

  .hard-skills-text > h1 {
    font-size: 18px;
  }
  .hard-skills-text > h2 {
    font-size: 14px;
  }
  .hard-skills-bubbles {
    width: 100%;
    padding-bottom: 5vh;
  }
}
