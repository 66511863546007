@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");

/*FOOTER*/
.footer {
  display: flex;
  background-color: whitesmoke;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
  height: auto;
}

/*CONTACT*/

.contact {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin: 2rem 6rem 0rem 0rem;
}

.contact--form {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}
.contact--form > input {
  width: 300px;
  height: 25px;
  margin: 5px;
  border: none;
  border-bottom: 1px solid rgb(59, 117, 234);
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  padding: 5px;
}

.contact--form > textarea {
  width: 300px;
  height: 100px;
  margin: 5px;
  border: none;
  border-bottom: 1px solid rgb(59, 117, 234);
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  padding: 5px;
  resize: none;
}

.contact--form > button {
  width: 100px;
  height: 30px;
  color: #fff;
  margin: 10px;
  background-color: rgb(59, 117, 234);
  border: none;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.contact--form > button {
  width: 200px;
  height: 50px;
  color: #fff;
  margin: 5px;
  background-color: rgb(59, 117, 234);
  border: none;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.contact--form > button:hover {
  background-color: rgba(59, 117, 234, 0.893);
  transition: 0.2s;
}

.contact > h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 32px;
}

.contact > h2 {
  color: rgb(59, 117, 234);
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  padding: 5px;
}

/*SITEMAP*/
.sitemap {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

.sitemap > h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 32px;
}
.sitemap > h2 {
  color: rgb(59, 117, 234);
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 20px;
}

li > a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  padding: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: large;
}

li > a:hover {
  color: rgb(59, 117, 234);
}

/*INFO*/
.info {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin: 0rem 0rem 0rem 6rem;
}

.logo {
  width: 125px;
  height: 125px;
  padding: 0 0 10px 10px;
}

.icon--div {
  padding: 10px;
  display: flex;
}

.icon {
  padding-right: 20px;
}

.icon--div > p {
  color: black;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: large;
}
.icon--div > a {
  color: black;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: large;
  text-decoration: none;
}

@media screen and (max-width: 820px) {
  .contact {
    margin: 2rem 0rem 0rem 0rem;
    justify-content: center;
    align-items: center;
  }
  .contact--form > button {
    align-self: center;
  }
  .sitemap {
    margin: 3rem 0rem 0rem 0rem;
    justify-content: center;
    align-items: center;
  }
  .info {
    margin: 3rem 0rem 0rem 0rem;
  }
  .logo--mobile {
    text-align: center;
  }
}
