@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");

.copyrights{
    background-color: #000;
    color: #fff;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}